import { gql } from "@apollo/client";

export const GET_COMPLAINTS = gql`
  query {
    complaint {
      id
      estate {
        title {
          lang
          value
        }
        id
      }
      status
      text
      created_at
    }
    badge
  }
`;

export const GET_COMPLAINT = gql`
  query ($id: Int) {
    complaint(id: $id) {
      id
      estate {
        title {
          lang
          value
        }
        id
      }
      status
      text
      email
      tree
      comment
      created_at
      user {
        type
        phone
        name
        email
      }
    }
  }
`;

export const GET_FAQS = gql`
  query {
    faq {
      id
      title {
        lang
        value
      }
      tab {
        name {
          lang
          value
        }
      }
    }
  }
`;

export const GET_FAQ = gql`
  query ($id: Int, $lang: String!) {
    faq(id: $id) {
      id
      title {
        lang
        value
      }
      text {
        lang
        value
      }
      tab {
        id
      }
    }
    faqTabs {
      id
      nameString(lang: $lang)
    }
  }
`;
