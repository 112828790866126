import { gql } from "@apollo/client";

export const MODIFY_COMPLAINT = gql`
  mutation ($id: Int!, $status: Int!, $comment: String) {
    modifyComplaint(id: $id, status: $status, comment: $comment)
  }
`;

export const DELETE_COMPLAINT = gql`
  mutation ($id: Int!) {
    deleteComplaint(id: $id) {
      id
      estate {
        title {
          lang
          value
        }
        id
      }
      status
      text
    }
  }
`;
