import { gql } from "@apollo/client";

export const GET_DATA_OBJECT = gql`
  query ($lang: String!, $id: Int) {
    steps {
      id
      name {
        lang
        value
      }
      name_system {
        lang
        value
      }
      is_system
      property_groups {
        id
        name {
          lang
          value
        }
        name_system {
          lang
          value
        }
        properties {
          id
          name(lang: $lang) {
            lang
            value
          }
        }
      }
    }
    objectType(id: $id) {
      id
      name {
        lang
        value
      }
      words {
        lang
        value
      }
      about_title {
        lang
        value
      }
      target
      estate_type
      has_complex
      steps
      propertyGroups
      properties
      enable_compare
      icon
      has_floor
      status
      status_dev
      sort
      complexes {
        type_id
      }
      squad_type
      has_metro_filter
    }
    list(method: "Complex::listType", lang: $lang) {
      id
      name
    }
    squadTypeList: list(method: "ObjectType::listSquad", lang: $lang) {
      id
      name
    }
  }
`;

export const GET_DATA_OBJECT_DESIGN = gql`
  query ($id: Int!) {
    objectType(id: $id) {
      stepsFull {
        name {
          lang
          value
        }
        id
        sort
        step_id
        propertyGroups(id: $id) {
          name {
            lang
            value
          }
          id
          sort
          properties(id: $id) {
            name {
              lang
              value
            }
            id
            sort
          }
        }
      }
    }
  }
`;

export const GET_FILTER_PROPS = gql`
  query ($id: Int!, $lang: String!) {
    property(object_type_id: $id, show_in_filter: 1) {
      nameString(lang: $lang)
      id
      sort_in_object(object_type_id: $id) {
        sort_in_filter
      }
    }
  }
`;

export const GET_ITEM_PROPS = gql`
  query ($id: Int!, $lang: String!) {
    property(object_type_id: $id, sort_item: true) {
      nameString(lang: $lang)
      id
      sort_in_object(object_type_id: $id) {
        sort_in_item
      }
    }
  }
`;
