import { Route, Routes } from "react-router-dom";
import "./assets/scss/icons.scss";
import "./assets/scss/config/default/app.scss";
import "./assets/scss/config/default/bootstrap.scss";
import "./assets/scss/global.scss";
import { Login } from "./pages/Login";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { ru } from "./messages/ru";
import { en } from "./messages/en";
import { az } from "./messages/az";
import React from "react";
import axios from "axios";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Home } from "./pages/Home";
import { Dictionaries } from "./pages/Dictionaries";
import { ProtectedRoute } from "./components/ProtectedRoute";
import { store } from "./redux/store";
import { useSelector } from "react-redux";
import { Form } from "./pages/Dictionaries/Form";
import { Steps } from "./pages/Steps";
import { StepForm } from "./pages/Steps/StepForm";
import { setAuth, setUser } from "./redux/slices/authSlice";
import { Countries } from "./pages/Countries";
import { CountryForm } from "./pages/Countries/CountryForm";
import { Cities } from "./pages/Cities";
import { CityForm } from "./pages/Cities/CityForm";
import { Areas } from "./pages/Areas";
import { FormArea } from "./pages/Areas/FormArea";
import { PropertyGroups } from "./pages/PropertyGroups";
import { FormPropertyGroups } from "./pages/PropertyGroups/FormPropertyGroups";
import { Properties } from "./pages/Properties";
import { FormProperties } from "./pages/Properties/FormProperties";
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  fromPromise,
  HttpLink,
  InMemoryCache,
} from "@apollo/client";
import { Pages } from "./pages/Pages";
import { PagesForm } from "./pages/Pages/PagesForm";
import { HomeSetting } from "./pages/HomeSetting";
import { Lang } from "./pages/Lang";
import { TypeObjects } from "./pages/TypeObjects";
import { TypeObjectForm } from "./pages/TypeObjects/TypeObjectForm";
import { FastLinks } from "./pages/FastLinks";
import { FastLinksForm } from "./pages/FastLinks/FastLinksForm";
import { Design } from "./pages/TypeObjects/Design";
import { Metro } from "./pages/Metro";
import { FormMetro } from "./pages/Metro/FormMetro";
import { Places } from "./pages/Places";
import { FormPlace } from "./pages/Places/FormPlace";
import { Complexes } from "./pages/Complexes";
import { Props } from "./pages/Complexes/Props";
import { FormComplex } from "./pages/Complexes/FormComplex";
import { Title } from "./pages/TypeObjects/Title";
import { ThumbProps } from "./pages/TypeObjects/ThumbProps";
import { Parsers } from "./pages/Parsers";
import { FormParser } from "./pages/Parsers/FormParser";
import { News } from "./pages/News";
import { FormNews } from "./pages/News/FormNews";
import { CategoryNews } from "./pages/CategoryNews";
import { FormCategoryNews } from "./pages/CategoryNews/FormCategoryNews";
import { TagsNews } from "./pages/TagsNews";
import { FormTagNews } from "./pages/TagNews/FormTagNews";
import { FooterMenu } from "./pages/FooterMenu";
import { FooterMenuForm } from "./pages/FooterMenu/FooterMenuForm";
import { Links } from "./pages/FooterMenu/Links";
import { LinkForm } from "./pages/FooterMenu/Links/LinkForm";
import { PropsAround } from "./pages/TypeObjects/PropsAround";
import { Points } from "./pages/Points";
import { FormPoint } from "./pages/Points/FormPoint";
import { FaqTabs } from "./pages/FaqTabs";
import { FaqTabsForm } from "./pages/FaqTabs/FaqTabsForm";
import { Faq } from "./pages/Faq";
import { FaqForm } from "./pages/Faq/FaqForm";
import { FilterSort } from "./pages/TypeObjects/FilterSort";
import { Estates } from "./pages/Estates";
import { FormEstate } from "./pages/Estates/FormEstate";
import { PromoCode } from "./pages/PromoCode";
import { PromoCodeForm } from "./pages/PromoCode/PromoCodeForm";
import { Stat } from "./pages/PromoCode/Stat";
import { TemplateNotifications } from "./pages/TemplateNotifications";
import { TemplateNotificationsForm } from "./pages/TemplateNotifications/TemplateNotificationsForm";
import { Complaint } from "./pages/Complaint";
import { ComplaintForm } from "./pages/Complaint/ComplaintForm";
import { Services } from "./pages/Services";
import { ServiceForm } from "./pages/Service/ServiceForm";
import { Tickets } from "./pages/Tickets";
import { TicketForm } from "./pages/Tickets/TicketForm";
import { EstateRejectReason } from "./pages/EstateRejectReason";
import { EstateRejectReasonForm } from "./pages/EstateRejectReason/EstateRejectReasonForm";
import { Settings } from "./pages/Settings";
import { AgentRequest } from "./pages/AgentRequest";
import { AgentRequestForm } from "./pages/AgentRequest/AgentRequestForm";
import { QuickFilters } from "./pages/TypeObjects/QuickFilters";
import { ItemSort } from "./pages/TypeObjects/ItemSort";
import { onError } from "@apollo/client/link/error";

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    // the translations
    // (tip move them in a JSON file and import them,
    // or even better, manage them via a UI: https://react.i18next.com/guides/multiple-translation-files#manage-your-translations-with-a-management-gui)
    resources: {
      ru: ru,
      en: en,
      az: az,
    },
    lng: store.getState().config.lang, // if you're using a language detector, do not define the lng option
    fallbackLng: store.getState().config.lang,

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  });

let isRefreshing = false;
let pendingRequests = [];

const resolvePendingRequests = (token) => {
  pendingRequests.map((callback) => callback(token));
  pendingRequests = [];
};

const errorLink = onError(
  ({ graphQLErrors, networkError, operation, forward }) => {
    if (graphQLErrors) {
      for (const err of graphQLErrors) {
        if (err.message === "Unauthorized") {
          if (!isRefreshing) {
            isRefreshing = true;
            const data = store.getState().auth;
            return fromPromise(
              fetch(`${process.env.REACT_APP_API_URL}/api/refresh`, {
                method: "POST",
                credentials: "include", // Якщо використовуєте cookies
                headers: {
                  "Content-Type": "application/json", // Зазначте формат даних
                  Authorization: `Bearer ${data?.auth?.token}`, // Додати токен до заголовка
                },
              })
                .then(async (response) => {
                  if (!response.ok) throw new Error("Failed to refresh token");
                  const data = await response.json();
                  store.dispatch(setAuth(data.authorization));
                  if (data.user !== null) {
                    store.dispatch(setUser(data.user));
                  }
                  resolvePendingRequests(data.authorization.token);
                  isRefreshing = false;
                  return data.authorization.token;
                })
                .catch((error) => {
                  pendingRequests = [];
                  isRefreshing = false;
                  console.error(error);
                  window.location.href = "/";
                }),
            )
              .filter(Boolean)
              .flatMap((newToken) => {
                operation.setContext({
                  headers: {
                    ...operation.getContext().headers,
                    authorization: `Bearer ${newToken}`,
                  },
                });
                return forward(operation);
              });
          }

          return fromPromise(
            new Promise((resolve) => {
              pendingRequests.push((token) => resolve(token));
            }),
          ).flatMap((token) => {
            operation.setContext({
              headers: {
                ...operation.getContext().headers,
                authorization: `Bearer ${token}`,
              },
            });
            return forward(operation);
          });
        }
      }
    }

    if (networkError && networkError.statusCode === 401) {
      console.error("Unauthorized");
    }
  },
);

const authLink = new ApolloLink((operation, forward) => {
  const data = store.getState().auth;
  operation.setContext({
    headers: {
      "Accept-Language": i18n.language,
      authorization: data?.auth?.token ? `Bearer ${data?.auth?.token}` : "",
    },
  });
  return forward(operation);
});
const httpLink = new HttpLink({
  uri: process.env.REACT_APP_API_URL + "/graphql",
});
const client = new ApolloClient({
  link: ApolloLink.from([errorLink, authLink, httpLink]),
  cache: new InMemoryCache(),
});

axios.defaults.baseURL = `${process.env.REACT_APP_API_URL}/api`;
//axios.defaults.headers.common["Lang"] = i18n.language;

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalConfig = error.config;
    if (error.response) {
      if (error.response.status === 401 && error.config.url !== "/refresh") {
        const newToken = await axios.post("/refresh");
        if (newToken?.data?.authorization?.token) {
          store.dispatch(setAuth(newToken?.data?.authorization));
          store.dispatch(setUser(newToken?.data?.user));
          originalConfig.headers = {
            ...originalConfig.headers,
            authorization: `Bearer ${newToken?.data?.authorization?.token}`,
          };
        }

        return axios(originalConfig);
      } else if (error.response.status === 401) {
        console.log("[Log]", error.response);
      }
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error);
  },
);

function App() {
  // const auth = useSelector((state) => state.auth.auth);
  // if (auth) {
  //   axios.defaults.headers.common["Authorization"] = `Bearer ${auth.token}`;
  // }
  return (
    <ApolloProvider client={client}>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/home"
          element={
            <ProtectedRoute>
              <Home />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dictionaries"
          element={
            <ProtectedRoute>
              <Dictionaries url={"/dictionaries"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dictionaries/form"
          element={
            <ProtectedRoute>
              <Form />
            </ProtectedRoute>
          }
        />
        <Route
          path="/steps"
          element={
            <ProtectedRoute>
              <Steps />
            </ProtectedRoute>
          }
        />
        <Route
          path="/steps/form"
          element={
            <ProtectedRoute>
              <StepForm />
            </ProtectedRoute>
          }
        />
        <Route
          path="/countries"
          element={
            <ProtectedRoute>
              <Countries url={"/countries"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/countries/form"
          element={
            <ProtectedRoute>
              <CountryForm url={"/countries"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cities"
          element={
            <ProtectedRoute>
              <Cities url={"/cities"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/cities/form"
          element={
            <ProtectedRoute>
              <CityForm url={"/cities"} />
            </ProtectedRoute>
          }
        />
        {/*Районы*/}
        <Route
          path="/areas"
          element={
            <ProtectedRoute>
              <Areas url={"/areas"} />
            </ProtectedRoute>
          }
        />
        {/*Ориентиры*/}
        <Route
          path="/points"
          element={
            <ProtectedRoute>
              <Points url={"/points"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/points/form"
          element={
            <ProtectedRoute>
              <FormPoint url={"/points"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/areas/form"
          element={
            <ProtectedRoute>
              <FormArea url={"/areas"} />
            </ProtectedRoute>
          }
        />
        {/*Группы свойств*/}
        <Route
          path="/property-groups"
          element={
            <ProtectedRoute>
              <PropertyGroups url={"/property-groups"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/property-groups/form"
          element={
            <ProtectedRoute>
              <FormPropertyGroups url={"/property-groups"} />
            </ProtectedRoute>
          }
        />
        {/*Свойства*/}
        <Route
          path="/properties"
          element={
            <ProtectedRoute>
              <Properties url={"/properties"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/properties/form"
          element={
            <ProtectedRoute>
              <FormProperties url={"/properties"} />
            </ProtectedRoute>
          }
        />
        {/*Страницы*/}
        <Route
          path="/pages"
          element={
            <ProtectedRoute>
              <Pages url={"/pages"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/pages/form"
          element={
            <ProtectedRoute>
              <PagesForm url={"/pages"} />
            </ProtectedRoute>
          }
        />
        {/*Быстрые ссылки*/}
        <Route
          path="/fast-links"
          element={
            <ProtectedRoute>
              <FastLinks url={"/fast-links"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/fast-links/form"
          element={
            <ProtectedRoute>
              <FastLinksForm url={"/fast-links"} />
            </ProtectedRoute>
          }
        />
        {/*Настройки*/}
        <Route
          path="/home-setting"
          element={
            <ProtectedRoute>
              <HomeSetting url={"/home-setting"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/settings"
          element={
            <ProtectedRoute>
              <Settings url={"/settings"} />
            </ProtectedRoute>
          }
        />
        {/*Переводы*/}
        <Route
          path="/lang"
          element={
            <ProtectedRoute>
              <Lang url={"/lang"} />
            </ProtectedRoute>
          }
        />
        {/*Недвижимость*/}
        <Route
          path="/type-objects"
          element={
            <ProtectedRoute>
              <TypeObjects url={"/type-objects"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/type-objects/form"
          element={
            <ProtectedRoute>
              <TypeObjectForm url={"/type-objects"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/type-objects/design"
          element={
            <ProtectedRoute>
              <Design url={"/type-objects"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/type-objects/title"
          element={
            <ProtectedRoute>
              <Title url={"/type-objects"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/type-objects/thumb-props"
          element={
            <ProtectedRoute>
              <ThumbProps url={"/type-objects"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/type-objects/props-around"
          element={
            <ProtectedRoute>
              <PropsAround url={"/type-objects"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/type-objects/filter-sort"
          element={
            <ProtectedRoute>
              <FilterSort url={"/type-objects"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/type-objects/item-sort"
          element={
            <ProtectedRoute>
              <ItemSort url={"/type-objects"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/type-objects/quick-filter"
          element={
            <ProtectedRoute>
              <QuickFilters url={"/type-objects"} />
            </ProtectedRoute>
          }
        />
        {/*Метро*/}
        <Route
          path="/metro"
          element={
            <ProtectedRoute>
              <Metro url={"/metro"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/metro/form"
          element={
            <ProtectedRoute>
              <FormMetro url={"/metro"} />
            </ProtectedRoute>
          }
        />
        {/*Поселки*/}
        <Route
          path="/places"
          element={
            <ProtectedRoute>
              <Places url={"/places"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/places/form"
          element={
            <ProtectedRoute>
              <FormPlace url={"/places"} />
            </ProtectedRoute>
          }
        />
        {/*ЖК*/}
        <Route
          path="/complexes"
          element={
            <ProtectedRoute>
              <Complexes url={"/complexes"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/complexes/props"
          element={
            <ProtectedRoute>
              <Props url={"/complexes"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/complexes/form"
          element={
            <ProtectedRoute>
              <FormComplex url={"/complexes"} />
            </ProtectedRoute>
          }
        />
        {/*Парсеры*/}
        <Route
          path="/parsers"
          element={
            <ProtectedRoute>
              <Parsers url={"/parsers"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/parsers/form"
          element={
            <ProtectedRoute>
              <FormParser url={"/parsers"} />
            </ProtectedRoute>
          }
        />
        {/*Новости*/}
        <Route
          path="/news"
          element={
            <ProtectedRoute>
              <News url={"/news"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/news/form"
          element={
            <ProtectedRoute>
              <FormNews url={"/news"} />
            </ProtectedRoute>
          }
        />
        {/*Категории новостей*/}
        <Route
          path="/category-news"
          element={
            <ProtectedRoute>
              <CategoryNews url={"/category-news"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/category-news/form"
          element={
            <ProtectedRoute>
              <FormCategoryNews url={"/category-news"} />
            </ProtectedRoute>
          }
        />
        {/*Теги новостей*/}
        <Route
          path="/tag-news"
          element={
            <ProtectedRoute>
              <TagsNews url={"/tag-news"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tag-news/form"
          element={
            <ProtectedRoute>
              <FormTagNews url={"/tag-news"} />
            </ProtectedRoute>
          }
        />
        {/*Меню в футере*/}
        <Route
          path="/footer-menu"
          element={
            <ProtectedRoute>
              <FooterMenu url={"/footer-menu"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/footer-menu/form"
          element={
            <ProtectedRoute>
              <FooterMenuForm url={"/footer-menu"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/footer-menu/links"
          element={
            <ProtectedRoute>
              <Links url={"/footer-menu/links"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/footer-menu/links/form"
          element={
            <ProtectedRoute>
              <LinkForm url={"/footer-menu/links/form"} />
            </ProtectedRoute>
          }
        />
        {/*FAQ*/}
        <Route
          path="/faq-tabs"
          element={
            <ProtectedRoute>
              <FaqTabs url={"/faq-tabs"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/faq-tabs/form"
          element={
            <ProtectedRoute>
              <FaqTabsForm url={"/faq-tabs"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/faq"
          element={
            <ProtectedRoute>
              <Faq url={"/faq"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/faq/form"
          element={
            <ProtectedRoute>
              <FaqForm url={"/faq"} />
            </ProtectedRoute>
          }
        />
        {/*Объявления*/}
        <Route
          path="/estates"
          element={
            <ProtectedRoute>
              <Estates url={"/estates"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estates/form"
          element={
            <ProtectedRoute>
              <FormEstate url={"/estates"} />
            </ProtectedRoute>
          }
        />
        {/*Промо коды*/}
        <Route
          path="/promo-codes"
          element={
            <ProtectedRoute>
              <PromoCode url={"/promo-codes"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/promo-codes/form"
          element={
            <ProtectedRoute>
              <PromoCodeForm url={"/promo-codes"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/promo-codes/stat"
          element={
            <ProtectedRoute>
              <Stat url={"/promo-codes"} />
            </ProtectedRoute>
          }
        />
        {/*Шаблоны уведомлений*/}
        <Route
          path="/template-notifications"
          element={
            <ProtectedRoute>
              <TemplateNotifications url={"/template-notifications"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/template-notifications/form"
          element={
            <ProtectedRoute>
              <TemplateNotificationsForm url={"/template-notifications"} />
            </ProtectedRoute>
          }
        />
        {/*Жалобы*/}
        <Route
          path="/complaints"
          element={
            <ProtectedRoute>
              <Complaint url={"/complaints"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/complaints/form"
          element={
            <ProtectedRoute>
              <ComplaintForm url={"/complaints"} />
            </ProtectedRoute>
          }
        />
        {/*Услуги*/}
        <Route
          path="/services"
          element={
            <ProtectedRoute>
              <Services url={"/services"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/services/form"
          element={
            <ProtectedRoute>
              <ServiceForm url={"/services"} />
            </ProtectedRoute>
          }
        />
        {/*Тикеты*/}
        <Route
          path="/tickets"
          element={
            <ProtectedRoute>
              <Tickets url={"/tickets"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/tickets/form"
          element={
            <ProtectedRoute>
              <TicketForm url={"/tickets"} />
            </ProtectedRoute>
          }
        />
        {/*Причины отклонения*/}
        <Route
          path="/estate-reject-reasons"
          element={
            <ProtectedRoute>
              <EstateRejectReason url={"/estate-reject-reasons"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/estate-reject-reasons/form"
          element={
            <ProtectedRoute>
              <EstateRejectReasonForm url={"/estate-reject-reasons"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agent-request"
          element={
            <ProtectedRoute>
              <AgentRequest url={"/agent-request"} />
            </ProtectedRoute>
          }
        />
        <Route
          path="/agent-request/form"
          element={
            <ProtectedRoute>
              <AgentRequestForm url={"/agent-request"} />
            </ProtectedRoute>
          }
        />
      </Routes>
      <ToastContainer />
    </ApolloProvider>
  );
}

export default App;
