import { gql } from "@apollo/client";

export const GET_TICKETS = gql`
  query ($lang: String!) {
    ticket {
      id
      created_at
      phone
      type
      status
    }
    list(method: "Ticket::listStatus", lang: $lang) {
      id
      name
    }
    badge
  }
`;

export const GET_TICKET = gql`
  query ($id: Int, $lang: String!) {
    ticket(id: $id) {
      id
      created_at
      phone
      type
      status
      comment
      step
      estates {
        id
        title {
          lang
          value
        }
      }
    }
    listType: list(method: "Ticket::listType", lang: $lang) {
      id
      name
    }
    listStatus: list(method: "Ticket::listStatus", lang: $lang) {
      id
      name
    }
  }
`;
