import React, { useEffect, useState } from "react";
import { Wrapper } from "../components/Wrapper";
import { PageTitle } from "../components/PageTitle";
import { CustomTable } from "../components/CustomTable";
import { useNavigate } from "react-router-dom";
import { Notification } from "../utils/Notification";
import { useMutation, useQuery } from "@apollo/client";
import { DELETE_FAQ } from "../graphql/Mutations/FaqMutation";
import { GET_AGENT_REQUESTS } from "../graphql/Queries/AgentRequestQuery";
import i18next from "i18next";
import moment from "moment";
import { DELETE_AGENT_REQUEST } from "../graphql/Mutations/AgentRequestMutation";

export const AgentRequest = ({ url }) => {
  const [items, setItems] = useState([]);
  const navigator = useNavigate();
  const [stat, setStat] = useState(null);

  const { data } = useQuery(GET_AGENT_REQUESTS, {
    fetchPolicy: "no-cache",
    variables: {
      lang: i18next.language,
    },
  });
  const [drop] = useMutation(DELETE_AGENT_REQUEST);

  useEffect(() => {
    if (data) {
      setStat(JSON.parse(data?.agentRequestStat));
      setItems(data.agentRequest);
    }
  }, [data]);

  const remove = (id) => {
    drop({
      variables: {
        id: Number(id),
        lang: i18next.language,
      },
    })
      .then((response) => {
        setItems(response.data.deleteAgentRequest);
      })
      .catch((error) => {
        Notification(error);
      });
  };

  const edit = (id) => {
    navigator(`${url}/form?id=${id}`);
  };

  return (
    <Wrapper>
      <PageTitle title={"Запросы на агента"} />
      <div className="row">
        <div className="col-md-12">
          <div className="card">
            <div className="card-body">
              <CustomTable
                stats={stat}
                data={items}
                columns={[
                  { key: "id", label: "ID" },
                  {
                    key: `created_at`,
                    label: "Дата",
                    callback: (item) => {
                      return moment(item).format("HH:mm DD.MM.YYYY");
                    },
                  },
                  {
                    key: `user`,
                    label: "Пользователь",
                    callback: (item) => {
                      return `ID: ${item.id}, Телефон: ${item.phone}, Email: ${item.email}`;
                    },
                  },
                  {
                    key: "status",
                    label: "Состояние",
                    callback: (item) => {
                      const status = data.list.find(
                        (e) => Number(e.id) === Number(item),
                      );
                      return status.name;
                    },
                  },
                ]}
                search={true}
                sort={true}
                pagination={{
                  limit: 10,
                }}
                onClick={(id) => edit(id)}
                onDelete={(id) => remove(id)}
              />
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  );
};
